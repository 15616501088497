import styled from "styled-components";

export const ArticleQaWrapper = styled.div`
    overflow: hidden;

    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`;

export const ControlsWrapper = styled.div`
    margin-bottom: 50px;
    padding: 20px;
    width: 392px;
    background: #ffffff;
    border-radius: 5px;

    div.ARTICLE_QA-audio-player {
        position: fixed;
        bottom: -15px;
        left: 0;
        width: 100%;
        z-index: 10000;

        user-select: none !important;

        .rhap_progress-container {
            margin: 0 calc(5px + 1%);
        }

        .rhap_main-controls {
            margin-left: -75px;
        }

        .rhap_progress-indicator,
        .rhap_progress-filled,
        .rhap_volume-indicator {
            background: #5a5e5f;
        }

        .rhap_time {
            font-family: Poppins;
            font-size: 15px;
            color: #5a5e5f;
        }

        button {
            color: #5a5e5f;
            &:focus {
                background: none;
            }
        }
    }
`;

export const StyledQaWordCard = styled.div<{ isActive?: boolean; isIgnoreButtonActive?: boolean; isPickupButtonActive?: boolean; isSelected?: boolean; isDisabled?: boolean }>`
    font-family: Poppins;
    padding: 21px 23px;
    border-radius: 5px;
    background: #fff;
    margin-bottom: 16px;
    width: 390px;
    transition: 0.15s ease opacity, 0.15s ease filter;
    opacity: ${(p) => (p.isSelected ? "0.5" : "1")};

    ${(p) => (!p.isDisabled ? "" : "filter: grayscale(1) contrast(0.85);")}
    ${(p) => (!p.isActive ? "" : "outline: 3px solid #006393;")}
    ${(p) => (p.isDisabled ? "" : "box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 8px 12px 0px rgba(9, 30, 66, 0.15);")}
    

    p.QA_WORD__timestamp {
        color: #505f79;
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        padding: 0;
        margin-bottom: 9px;
    }

    p.QA_WORD__heading {
        color: #000;
        font-size: 14px;
        font-weight: 400;
        margin: 0;
        padding: 0;
        margin-bottom: 12px;
    }

    div.QA_WORD__words_group {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        & > div {
            margin-right: 80px;
        }
    }

    p.QA_WORD__word_heading {
        color: #000;
        font-size: 11px;
        font-weight: 700;
        margin: 0;
        padding: 0;
        margin-bottom: 2px;
    }

    p.QA_WORD__word {
        color: #000;
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-weight: 400;
    }

    div.QA_WORD__action_buttons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 10px;

        button {
            padding: 6px 12px;
            font-size: 14px;
            font-weight: 500;
            font-family: inherit;
            border-radius: 3px;
            outline: none;
            border: none;
            cursor: ${(p) => (p.isDisabled ? "auto" : "pointer")};
            transition: 0.1s ease filter;
            &:hover {
                filter: ${(p) => (p.isDisabled ? "brightness(1)" : "brightness(0.9)")};
            }

            &.QA_WORD__action_buttons-pickup {
                background: ${(p) => (p.isPickupButtonActive ? "#bf2600" : "#ffbdad")};
                color: ${(p) => (p.isPickupButtonActive ? "#FFBDAD" : "#bf2600")};
                margin-right: 10px;
            }

            &.QA_WORD__action_buttons-ignore-all {
                background: rgba(179, 212, 255, 0.6);
                color: #007fbc;
                margin-right: 10px;
            }

            &.QA_WORD__action_buttons-ignore {
                background: ${(p) => (p.isIgnoreButtonActive ? " #007fbc" : "rgba(179, 212, 255, 0.6)")};
                color: ${(p) => (p.isIgnoreButtonActive ? "#E8F7FF" : "#007fbc")};
                margin-right: 10px;
            }

            &.QA_WORD__action_buttons-play {
                background: #efefef;
                color: #3a3a3a;
            }
        }
    }
`;

export const BottomActions = styled.div`
    position: fixed;
    bottom: 84px;
    left: 0;
    backdrop-filter: blur(20px);
    width: 510px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 15px 0px;
    padding-left: 42px;
    padding-right: 79px;

    p {
        margin: 0;
        padding: 0;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
    }
`;

export const QaPlayerSpeedButton = styled.button`
    font-family: Poppins;
    border: none;
    background: none;
    padding: 5px;
    margin: 0;
    cursor: pointer;
    font-size: 22px !important;
`;

export const WordWrapper = styled.span`
    transition: all 0.1s ease;
    border-radius: 2px;
    &:hover {
        background: rgba(0, 0, 0, 0.1);
    }
    &:active {
        background: rgba(179, 212, 255, 0.6);
        color: #007fbc;
    }
`;
