import { FC, RefObject, useEffect } from "react";
import toast from "react-hot-toast";

import { IDiffExtendedItemWithGroup, IQaPron } from "./types";

import { WordWrapper } from "./styles";

interface IProps {
    sttEngine: string;
    transcriptId: string;
    diff: IDiffExtendedItemWithGroup[];
    prons: IQaPron[];
    getActiveItem: () => IQaPron | undefined;
    qaTextWrapperRef: RefObject<HTMLDivElement>;
    currentAudioTime: number | null;
    seekAudioToSeconds: (startTimeSeconds: number, endTimeSeconds?: number, customOffset?: number) => void;
}

const activeQaPronStyles = {
    color: "#ffffff",
    background: "#202020",
    fontWeight: "500",
};

const ArticlePronunciationsQaText: FC<IProps> = ({ sttEngine, transcriptId, diff, prons, getActiveItem, qaTextWrapperRef, seekAudioToSeconds, currentAudioTime }) => {
    const activeItem = getActiveItem();

    const playOnWordClick = (item: IDiffExtendedItemWithGroup) => {
        const { startTime } = item;

        if (!startTime) {
            toast("Timestamps are not available for this word.");
            return;
        }

        seekAudioToSeconds(startTime, undefined, 0.08);
    };

    const isCurrentWordActive = (item: IDiffExtendedItemWithGroup) => {
        const { startTime, endTime } = item;

        if (!startTime || !endTime || !currentAudioTime) {
            return false;
        }

        // endTime - startTime < 1: to handle the scenarios where there is a big gap between start and end time, this will prevent those false positives
        if (currentAudioTime > startTime && currentAudioTime < endTime) {
            return true;
        }

        return false;
    };

    useEffect(() => {
        const wrapperItem = qaTextWrapperRef.current;
        const item = wrapperItem?.querySelector(".QA_PRON__active");
        if (wrapperItem && item) {
            wrapperItem.scrollTo({ top: (item as any).offsetTop + 20 - (wrapperItem.getBoundingClientRect().height - item.getBoundingClientRect().height) / 2, behavior: "smooth" });
        }
    }, [activeItem?.diffItems?.[0]?.id]);

    return (
        <p
            style={{
                color: "#000000",
                fontSize: "16px",
                lineHeight: "24px",
                background: "#fff",
                boxShadow: "0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 8px 12px 0px rgba(9, 30, 66, 0.15)",
                padding: "30px",
                paddingTop: "20px",
                fontFamily: "Poppins",
                maxWidth: "700px",
                margin: "0",
                borderRadius: "5px",
                width: "100%",
            }}
        >
            <span style={{ fontSize: "12px", color: "#727984" }}>
                <span style={{ fontWeight: 700, textTransform: "uppercase", marginRight: "12px" }}>{sttEngine}</span>
                <span style={{ fontWeight: 500 }}>{transcriptId}</span>
            </span>
            <br />
            <br />
            {diff.map((item) => {
                if (item.type === "INSERTION") return null;
                if (item.type === "NOISE") {
                    if (item.lineBreakDiffItem) {
                        return (
                            <>
                                <br />
                                <br />
                            </>
                        );
                    }

                    return null;
                }
                const isItemActive = !!activeItem && activeItem.diffItems.some((di) => di.id === item.id);

                const isItemPron = prons.some((p) => p.diffItems.some((di) => di.id === item.id));

                const isWordActive = isCurrentWordActive(item);
                let wordActiveStyles = {};

                if (isWordActive) {
                    wordActiveStyles = { color: "#fff", background: "#007fbc" };
                }

                if (isItemPron) {
                    return (
                        <WordWrapper
                            onClick={() => playOnWordClick(item)}
                            key={item.id}
                            className={`${isItemActive ? "QA_PRON__active" : ""}`}
                            style={{ fontWeight: "700", cursor: "pointer", ...(isItemActive ? activeQaPronStyles : {}), ...wordActiveStyles }}
                        >
                            {" "}
                            {item.reference}{" "}
                        </WordWrapper>
                    );
                }

                return (
                    <WordWrapper onClick={() => playOnWordClick(item)} style={{ cursor: "pointer", ...wordActiveStyles }} key={item.id}>
                        {" "}
                        {item.reference}
                    </WordWrapper>
                );
            })}
        </p>
    );
};

export default ArticlePronunciationsQaText;
