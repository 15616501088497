import _ from "lodash";
import config from "src/config";
import IPronunciation from "src/types/pronunciation";

export function cleanIntroOutroForPreviewText(text: string) {
    return (
        text
            // .replace(/_+ +(INTRO|OUTRO)/g, "")
            // .replace(/(_ )+(INTRO|OUTRO)/g, "")
            // .replace(/^OUTRO/, "")
            // .replace(/(_ ){2,}/g, "")
            // .replace(/_{2,}/g, "")
            // .replace(/-{2,}/g, "")
            .trim()
    );
}

export const isAiPhonetic = (pron: IPronunciation) => !!pron.aiPhonetic?.trim()?.length;

export const replaceAndMergeTextWithPronunciation = (
    text: string,
    prons: { pron: IPronunciation; word: string }[],
    newLines: any[],
    //@ts-ignore
    transformPronFn: (word: string, prevWord?: string, nextWord?: string, current: { pron: IPronunciation; word: string }) => any,
) => {
    return text
        .split("\n")
        .filter((para) => !!para.trim().length)
        .map((para): any[] => {
            const paraTransformed = prons.reduce(
                (combined: any[], current) => {
                    const word = current.word;
                    const pronRegex = new RegExp(`(?<=\\s|^|[^\\w:.])${_.escapeRegExp(word)}(?!:\\d)(?=\\s|$|[^\\w,.]|[.,](?!\\d))`, "g");

                    const transformed = combined.map((item) => {
                        if (typeof item === "string") {
                            const itemTrimmed = item.trim();

                            const itemMatch = item.match(pronRegex);
                            if (!itemMatch || itemMatch.length < 1) {
                                return itemTrimmed;
                            }

                            // removing the name and splitting the string
                            const itemSplit = itemTrimmed.split(pronRegex) as any[];

                            // after removing the name and splitting, now add the elem at the odd positions(because\
                            // the name will always be removed from the odd positions)

                            for (let i = 1; i < itemSplit.length; i++) {
                                if (i % 2 !== 0) {
                                    const nextWord = itemSplit?.[i];
                                    const prevWord = itemSplit?.[i - 1];
                                    const pronTransformed = transformPronFn(word, prevWord, nextWord, current);
                                    itemSplit.splice(i, 0, pronTransformed);
                                }
                            }

                            // this will cause 2d arrays
                            return itemSplit;
                        }

                        return item;
                    });

                    // to flat the 2d arrays
                    return transformed.flat();
                },
                [para],
            );

            return [paraTransformed, ...newLines];
        });
};

export const highlightWords = (
    text: any[],
    //@ts-ignore
    transformFn: (current, color?: string) => any,
    isAiArticle?: boolean,
) => {
    const warnWords = config.warnKeywords.sort((a, b) => b.length - a.length);

    let transformedText = text
        .map((item) => {
            if (typeof item === "string") {
                return warnWords.reduce(
                    (combined: any[], current: string) => {
                        const wordRegex = new RegExp("\\b" + _.escapeRegExp(current) + "\\b", "g");

                        return combined
                            .map((str) => {
                                if (typeof str !== "string") {
                                    return str;
                                }

                                if (!wordRegex.test(str)) {
                                    return str;
                                }

                                // removing the name and splitting the string
                                const itemSplit = str.split(wordRegex) as any[];

                                // after removing the name and splitting, now add the elem at the odd positions(because\
                                // the name will always be removed from the odd positions)

                                for (let i = 1; i < itemSplit.length; i++) {
                                    if (i % 2 !== 0) {
                                        const wordTransformed = transformFn(current);
                                        itemSplit.splice(i, 0, wordTransformed);
                                    }
                                }

                                // this will cause 2d arrays
                                return itemSplit;
                            })
                            .flat();
                    },
                    [item],
                );
            }

            return item;
        })
        .flat(Infinity);

    if (isAiArticle) {
        const heteronyms = config.heteronyms.sort((a, b) => b.length - a.length);

        transformedText = transformedText
            .map((item) => {
                if (typeof item === "string") {
                    return heteronyms.reduce(
                        (combined: any[], current: string) => {
                            const wordRegex = new RegExp("\\b" + _.escapeRegExp(current) + "\\b", "gi");

                            return combined
                                .map((str) => {
                                    if (typeof str !== "string") {
                                        return str;
                                    }

                                    const matches = str.match(wordRegex);
                                    if (!matches?.length) {
                                        return str;
                                    }

                                    // removing the name and splitting the string
                                    const itemSplit = str.split(wordRegex) as any[];

                                    // after removing the name and splitting, now add the elem at the odd positions(because\
                                    // the name will always be removed from the odd positions)

                                    let matchesIndex = 0;
                                    for (let i = 1; i < itemSplit.length; i++) {
                                        if (i % 2 !== 0) {
                                            const originalMatchWord = matches[matchesIndex];

                                            const wordTransformed = transformFn(originalMatchWord, "#f68900");
                                            itemSplit.splice(i, 0, wordTransformed);

                                            matchesIndex += 1;
                                        }
                                    }

                                    // this will cause 2d arrays
                                    return itemSplit;
                                })
                                .flat();
                        },
                        [item],
                    );
                }

                return item;
            })
            .flat(Infinity);
        transformedText = transformedText
            .map((item) => {
                if (typeof item === "string") {
                    const numberRegex = /(\d((,\d+)?)+)+(\.\d+)?/gi;

                    const itemMatch = item.match(numberRegex);
                    if (!itemMatch) return item;

                    return itemMatch.reduce(
                        (combined: any[], current: string) => {
                            return combined
                                .map((str) => {
                                    if (typeof str !== "string") {
                                        return str;
                                    }

                                    if (!str.includes(current)) {
                                        return str;
                                    }

                                    // removing the name and splitting the string
                                    const itemSplit = str.split(current) as any[];

                                    // after removing the name and splitting, now add the elem at the odd positions(because\
                                    // the name will always be removed from the odd positions)

                                    for (let i = 1; i < itemSplit.length; i++) {
                                        if (i % 2 !== 0) {
                                            const wordTransformed = transformFn(current);
                                            itemSplit.splice(i, 0, wordTransformed);
                                        }
                                    }

                                    // this will cause 2d arrays
                                    return itemSplit;
                                })
                                .flat();
                        },
                        [item],
                    );
                }

                return item;
            })
            .flat(Infinity);
    }

    return transformedText;
};

export const shouldAddSpaceAfterOrBeforePron = (letter: string) => {
    if (letter.trim()) {
        return /^[^-?.,%;'’&]$/.test(letter.trim());
    }

    return true;
};

export const shouldAddSpaceBeforePronForSpecialCases = (prevWord?: string) => {
    if (!prevWord?.length) return true;

    // no space after $ symbol
    if (prevWord[prevWord.length - 1] === "$") return false;

    return true;
};
